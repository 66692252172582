import { Drawer as MDrawer } from "@mui/material";
import React from "react";
import Drawer from "../drawer/Drawer";
import { styled } from "@mui/material/styles";
import { DRAWER_WIDTH } from "./TopBar";

type SideNavigationProps = {
  isDrawerVisible: boolean;
  setIsDrawerVisible: any;
};

const NavDrawer = styled("nav")(
  ({ theme }) => `
    ${theme.breakpoints.up("sm")} {
      width: ${DRAWER_WIDTH}px;
      flexShrink: 0;
    }
`
);

const ContainerDrawer = styled(MDrawer)(
  ({ theme }) => `
    ${theme.breakpoints.up("sm")} {
      width: ${DRAWER_WIDTH}px;
      flexShrink: 0;
    }
    
    & .MuiDrawer-paper {
      width: ${DRAWER_WIDTH}px;
    }
`
);

export const SideDrawer: React.FC<SideNavigationProps> = (props) => {
  const { isDrawerVisible, setIsDrawerVisible } = props;

  const handleDrawerToggle = () => {
    setIsDrawerVisible(!isDrawerVisible);
  };

  return (
    <NavDrawer aria-label="mailbox folders">
      <ContainerDrawer
        variant="temporary"
        open={isDrawerVisible}
        onClose={handleDrawerToggle}
        sx={{
          display: { md: "none", xs: "block" },
        }}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <Drawer handleDrawerToggle={handleDrawerToggle} />
      </ContainerDrawer>

      <ContainerDrawer
        sx={{
          display: { xs: "none", md: "block" },
        }}
        variant="permanent"
        open
      >
        <Drawer />
      </ContainerDrawer>
    </NavDrawer>
  );
};
