import * as React from "react";
import { Grid, GridProps } from "@mui/material";

const GridItem = (props: GridProps) => {
  const { xs = 12, ...rest } = props;

  return (
    <Grid item xs={xs} {...rest}>
      {props.children}
    </Grid>
  );
};

export default GridItem;
