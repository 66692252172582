import React, { useState } from "react";
import {
  Button,
  Card,
  CardContent,
  Typography,
  CardHeader,
} from "@mui/material";
import { CenteredTypography } from "../../../../shared/widgets/typography/CenteredTypography";
import { patientApi } from "../../../../api/patientApi";
import { useSnackbar } from "notistack";
import { RoutePaths } from "../../../../routes/routePaths";
import RemovePatientConfirmationModal from "./RemovePatientConfirmationModal";
import { PatientDto } from "../../../../endpoints";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface SecondaryPatientInformationProps {
  patient: PatientDto;
}

const SecondaryPatientInformation: React.FC<
  SecondaryPatientInformationProps
> = ({ patient }) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleClose = () => setOpen(false);

  const handleAccept = async () => {
    await patientApi.deletePatient(patient.id);
    setOpen(false);
    enqueueSnackbar(t("patient.removed"), {
      variant: "info",
    });
    navigate(RoutePaths.PATIENT_INDEX);
  };

  return (
    <Card>
      <RemovePatientConfirmationModal
        open={open}
        handleClose={handleClose}
        handleAccept={handleAccept}
      />

      <CardHeader
        title={t("patient.secondary.information")}
        titleTypographyProps={{
          variant: "subtitle1",
          color: "textSecondary",
        }}
        action={
          <Button
            onClick={() => setOpen(true)}
            variant="contained"
            color="primary"
          >
            {t("remove")}
          </Button>
        }
      />
      <CardContent>
        <Typography variant="body1">
          <>
            {t("created")} {new Date(patient?.createdAt).toLocaleDateString()}
          </>
        </Typography>
        <br />
        <CenteredTypography variant="body1">
          <>
            {t("last.updated")}{" "}
            {new Date(patient?.updatedAt).toLocaleDateString()}
          </>
        </CenteredTypography>
      </CardContent>
    </Card>
  );
};

export default SecondaryPatientInformation;
