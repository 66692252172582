import { StatusCodes } from "http-status-codes";
import { RegisterDuplicateUserFound } from "../exception/RegisterDuplicateUserFound";
import { ApiRoutes } from "../routes/routePaths";
import { RegisterWrongPayload } from "../exception/RegisterWrongPayload";

const register = async (data): Promise<void> => {
  const response = await fetch(ApiRoutes.REGISTER, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      username: data.username,
      email: data.email,
      password: data.password,
    }),
  });
  if (response.status === StatusCodes.OK) {
    return Promise.resolve();
  }
  if (response.status === StatusCodes.BAD_REQUEST) {
    return Promise.reject(new RegisterWrongPayload());
  }
  if (response.status == StatusCodes.CONFLICT) {
    return Promise.reject(new RegisterDuplicateUserFound());
  }
  return Promise.reject(new Error("Registration failed"));
};

const logout = async (): Promise<void> => {
  await fetch(ApiRoutes.LOGOUT, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });
  window.location.reload();
};

const isLogged = async (): Promise<boolean> => {
  const response = await fetch(ApiRoutes.IS_LOGGED, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  });

  const match = response.status === StatusCodes.OK;
  return Promise.resolve(match);
};

const activate = async (token: string): Promise<void> => {
  const response = await fetch(ApiRoutes.ACTIVATE(token), {
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
  });
  if (response.status === StatusCodes.OK) {
    return Promise.resolve();
  } else {
    return Promise.reject();
  }
};

const AuthService = {
  register,
  logout,
  isLogged,
  activate,
};

export default AuthService;
