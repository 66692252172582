import React, { useMemo, useRef, useState } from "react";
import { Typography, useMediaQuery } from "@mui/material";
import { Formik } from "formik";
import { diagnosisHistoryApi } from "../../../../../api/diagnosisHistoryApi";
import { useSnackbar } from "notistack";
import { useReactToPrint } from "react-to-print";
import { useTheme } from "@mui/material/styles";
import DiagnosisEventActionButtons from "./DiagnosisEventActionButtons";
import DiagnosisEventContentPrint from "./diagnosis-content/DiagnosisEventContentPrint";
import DiagnosisEventContent from "./diagnosis-content/DiagnosisEventContent";
import { useDiagnosisHistoryValidationSchema } from "../DiagnosisHistoryValidation";
import { DiagnosisHistoryDto } from "../../../../../endpoints";
import { useTranslation } from "react-i18next";
import { VerticalTimelineElement } from "react-vertical-timeline-component";
import AssignmentIndTwoToneIcon from "@mui/icons-material/AssignmentIndTwoTone";

interface DiagnosisEventProps {
  diagnosisEvent: DiagnosisHistoryDto;
  updatePatientDiagnosisHistory: () => void;
}

const DiagnosisEvent: React.FC<DiagnosisEventProps> = ({
  diagnosisEvent,
  updatePatientDiagnosisHistory,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isDeviceMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const [isEditModeEnabled, setIsEditModeEnabled] = useState<boolean>(false);
  const { t } = useTranslation();
  const [overridablePrintData, setOverridablePrintData] = useState<
    DiagnosisHistoryDto | undefined
  >(undefined);

  const componentForPrintingRef = useRef(null);

  const handleDiagnosisEventSubmit = async (submitForm) => {
    submitForm();
    setIsEditModeEnabled(!isEditModeEnabled);
  };

  const handlePrint = useReactToPrint({
    content: () => componentForPrintingRef.current,
    onAfterPrint: () => setOverridablePrintData(undefined),
  });

  const handleOverridablePrintData = (
    alteredDiagnosis: DiagnosisHistoryDto
  ) => {
    setOverridablePrintData(alteredDiagnosis);
  };

  const handleDelete = async (
    diagnosis: DiagnosisHistoryDto
  ): Promise<void> => {
    await diagnosisHistoryApi.deleteDiagnosisHistory(diagnosis);
    enqueueSnackbar(t("diagnosis.removed"), { variant: "success" });
    updatePatientDiagnosisHistory();
  };

  const handleUpdate = () => {
    setIsEditModeEnabled(!isEditModeEnabled);
  };

  const handleSubmit = (values: DiagnosisHistoryDto) => {
    diagnosisHistoryApi
      .updateDiagnosisHistory(values)
      .then(() => {
        updatePatientDiagnosisHistory();
        setIsEditModeEnabled(false);
        enqueueSnackbar(t("diagnosis.saved"), { variant: "success" });
      })
      .catch(() => {
        enqueueSnackbar(t("diagnosis.failed"), {
          variant: "error",
        });
      });
  };

  const renderDiagnosisPrint = useMemo(() => {
    return (
      <DiagnosisEventContentPrint
        diagnosisEvent={diagnosisEvent}
        refPrint={componentForPrintingRef}
        overridablePrintData={overridablePrintData}
      />
    );
  }, [diagnosisEvent, overridablePrintData]);

  return (
    <>
      <Formik
        validationSchema={useDiagnosisHistoryValidationSchema()}
        initialValues={diagnosisEvent}
        onSubmit={handleSubmit}
      >
        {({ submitForm }) => {
          return (
            <VerticalTimelineElement
              key={diagnosisEvent.id}
              contentStyle={{
                padding: "15px",
                boxShadow: "none",
                borderRadius: "0",
                borderBottom: "1px solid #eee",
              }}
              createdAt={
                isDeviceMdUp ? (
                  <Typography variant="subtitle2" color="textSecondary">
                    {new Date(diagnosisEvent.updatedAt).toLocaleDateString()}
                  </Typography>
                ) : null
              }
              icon={<AssignmentIndTwoToneIcon />}
              iconColor={theme.palette.primary.main}
              iconStyle={{ boxShadow: "none", paddingTop: "30px" }}
              contentArrowStyle={{ display: "none" }}
              date={new Date(diagnosisEvent.updatedAt).toLocaleDateString()}
            >
              <DiagnosisEventActionButtons
                isEditModeEnabled={isEditModeEnabled}
                diagnosisEvent={diagnosisEvent}
                handleSubmit={() => handleDiagnosisEventSubmit(submitForm)}
                handlePrint={handlePrint}
                handleDelete={() => handleDelete(diagnosisEvent)}
                handleUpdate={handleUpdate}
                handleOverridablePrintData={handleOverridablePrintData}
              />
              <DiagnosisEventContent
                isEditModeEnabled={isEditModeEnabled}
                diagnosisEvent={diagnosisEvent}
              />
              {componentForPrintingRef && renderDiagnosisPrint}
            </VerticalTimelineElement>
          );
        }}
      </Formik>
    </>
  );
};

export default DiagnosisEvent;
