import React from "react";
import TopBar from "./navigation/TopBar";
import { SideDrawer } from "./navigation/SideDrawer";
import { styled } from "@mui/material/styles";

const NavigationContainer = styled("div")(
  () => `
    display: "flex";
`
);

const Navigation = () => {
  const [isDrawerVisible, setIsDrawerVisible] = React.useState<boolean>(false);

  return (
    <NavigationContainer>
      <TopBar
        isDrawerVisible={isDrawerVisible}
        setIsDrawerVisible={setIsDrawerVisible}
      />
      <SideDrawer
        isDrawerVisible={isDrawerVisible}
        setIsDrawerVisible={setIsDrawerVisible}
      />
    </NavigationContainer>
  );
};

export default Navigation;
