import React, { useEffect, useState } from "react";
import {
  DiagnosisCodeApiResponse,
  diagnosisCodesApi,
} from "../../../api/diagnosisCodesApi";
import { useTranslation } from "react-i18next";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

const DiagnosisCodeIndex = () => {
  const { t } = useTranslation();
  const [diagnosisCodes, setDiagnosisCodes] = useState<
    DiagnosisCodeApiResponse[] | null
  >(null);

  useEffect(() => {
    const run = async () => {
      setDiagnosisCodes(await diagnosisCodesApi.getDiagnosisCodes());
    };
    void run();
  }, []);

  const columns: GridColDef[] = [
    {
      headerName: t("code"),
      field: "code",
    },
    { headerName: t("disease"), field: "disease", width: 500 },
  ];

  return (
    <div>
      {diagnosisCodes && (
        <DataGrid rows={diagnosisCodes} columns={columns} autoHeight />
      )}
    </div>
  );
};

export default DiagnosisCodeIndex;
