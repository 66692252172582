import styled from "styled-components";
import { Typography } from "@mui/material";

export const CenteredTypography = styled(Typography)`
  display: flex;
  align-items: center;
  svg {
    margin-right: 5px;
  }
`;
