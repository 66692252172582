import React from "react";
import { Container } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import { RoutePaths } from "../routePaths";
import { PromptUser } from "../../pages/user-confirmation/PromptUser";
import { ResendConfirmation } from "../../pages/user-confirmation/ResendConfirmation";
import { ActivateUser } from "../../pages/user-confirmation/ActivateUser";

export const UserConfirmation = (): JSX.Element => {
  return (
    <div>
      <main>
        <Container>
          <Routes>
            <Route
              path={RoutePaths.USER_CONFIRMATION_PROMPT}
              Component={PromptUser}
            />
            <Route
              path={RoutePaths.USER_CONFIRMATION_ACTIVATE}
              Component={ActivateUser}
            />
            <Route
              path={RoutePaths.USER_CONFIRMATION_RESEND}
              Component={ResendConfirmation}
            />
          </Routes>
        </Container>
      </main>
    </div>
  );
};
