import React, { FC, useEffect, useState } from "react";
import { patientApi } from "../../../api/patientApi";
import { PatientDto } from "../../../endpoints";
import { useTranslation } from "react-i18next";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridToolbarQuickFilter,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { RoutePaths } from "../../../routes/routePaths";
import { styled } from "@mui/material/styles";

const QuickSearchToolbar = styled(GridToolbarQuickFilter)(
  ({ theme }) => `
    padding: 10px;
    ${theme.breakpoints.up("sm")} {
      flex-direction: row-reverse;
    }
  `
);

const PatientIndex: FC = () => {
  const [patients, setPatients] = useState<PatientDto[] | undefined>(undefined);
  const { t } = useTranslation();

  useEffect(() => {
    const run = async () => {
      setPatients(await patientApi.getAllPatients());
    };
    void run();
  }, []);

  const columns: GridColDef[] = [
    {
      headerName: t("fullname"),
      field: "fullname",
      width: 200,
      valueGetter: (params: GridValueGetterParams<PatientDto>) =>
        params.row.fullName,
      renderCell: (params: GridRenderCellParams<PatientDto>) => (
        <Typography>
          <Link to={RoutePaths.PATIENT_SHOW(params.row.id)}>
            {params.row.fullName}
          </Link>
        </Typography>
      ),
    },
    { headerName: t("email"), field: "email", width: 200 },
    { headerName: t("telephone"), field: "phone", width: 200 },
  ];

  return (
    <div>
      {patients && (
        <DataGrid
          rows={patients}
          columns={columns}
          autoHeight
          disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          slots={{ toolbar: QuickSearchToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 150 },
            },
          }}
        />
      )}
    </div>
  );
};

export default PatientIndex;
