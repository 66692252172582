import React, { useState } from "react";
import {
  AppBar,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import { AuthService } from "../../../auth";
import { useTranslation } from "react-i18next";
import { LANGUAGE, updateLanguage } from "../../../i18/i18";
import { styled } from "@mui/material/styles";

interface MainNavigationProps {
  isDrawerVisible: boolean;
  setIsDrawerVisible: any;
}

export const DRAWER_WIDTH = 240;

const BonadermaBar = styled(AppBar)(
  ({ theme }) => `
    ${theme.breakpoints.up("md")} {
      width: calc(100% - ${DRAWER_WIDTH}px);
      margin-left: 47px;
    }
`
);

const MenuButton = styled(IconButton)(
  ({ theme }) => `
    margin-right: ${theme.spacing(2)};
    ${theme.breakpoints.up("md")} {
      display: none;
    }
`
);

const ToolbarStyled = styled(Toolbar)(
  ({ theme }) => `
    ${theme.mixins.toolbar};
    min-height: 47px;
`
);

const TopBar: React.FC<MainNavigationProps> = (props: MainNavigationProps) => {
  const { setIsDrawerVisible } = props;
  const { t } = useTranslation();

  const [menuAnchor, setMenuAnchor] = useState<undefined | HTMLElement>(
    undefined
  );
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState<boolean>(false);

  const openProfileMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(e.currentTarget);
    setIsProfileMenuOpen(true);
  };

  const closeProfileMenu = () => setIsProfileMenuOpen(false);

  const handleOnUpdateLanguageClick = (language: LANGUAGE) => {
    updateLanguage(language);
    closeProfileMenu();
  };

  const logout = () => {
    void AuthService.logout();
  };

  return (
    <BonadermaBar position="absolute">
      <ToolbarStyled variant="dense">
        <MenuButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={() => setIsDrawerVisible(true)}
          size="large"
        >
          <MenuRoundedIcon />
        </MenuButton>

        <Typography variant="h6" color="inherit" sx={{ flexGrow: 1 }}>
          {t("menu")}
        </Typography>

        <Button onClick={openProfileMenu} sx={{ color: "white" }}>
          {t("my.profile")}
        </Button>

        <Menu
          id="menu-appbar"
          anchorEl={menuAnchor}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={isProfileMenuOpen}
          onClose={closeProfileMenu}
        >
          <MenuItem onClick={logout}>{t("logout")}</MenuItem>
          <MenuItem onClick={() => handleOnUpdateLanguageClick(LANGUAGE.EN)}>
            EN
          </MenuItem>
          <MenuItem onClick={() => handleOnUpdateLanguageClick(LANGUAGE.MK)}>
            MK
          </MenuItem>
        </Menu>
      </ToolbarStyled>
    </BonadermaBar>
  );
};

export default TopBar;
