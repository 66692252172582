import React from "react";
import { Container, CssBaseline } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import Navigation from "./Navigation";
import PatientCreate from "../../pages/patient/create/PatientCreate";
import PatientShow from "../../pages/patient/show/PatientShow";
import PatientIndex from "../../pages/patient/index/PatientIndex";
import { RoutePaths } from "../routePaths";
import DiagnosisCodeIndex from "../../pages/diagnosisCode/index/DiagnosisCodeIndex";
import { styled } from "@mui/material/styles";
import { DRAWER_WIDTH } from "./navigation/TopBar";

const Main = styled("div")(
  ({ theme }) => `
    flexGrow: 1;
    height: 100vh;
    overflow: auto;
    ${theme.breakpoints.up("md")} {
        margin-left: ${DRAWER_WIDTH}px;
    };
`
);

export const Toolbar = styled("div")(
  ({ theme }) => `
    ${theme.mixins.toolbar};
    min-height: 47px;
`
);

const DashboardContainer = styled(Container)(
  ({ theme }) => `
    padding-top: ${theme.spacing(4)};
    padding-bottom: ${theme.spacing(4)};
`
);

function Dashboard() {
  return (
    <div>
      <CssBaseline />
      <Navigation />
      <Main>
        <Toolbar />
        <DashboardContainer>
          <Routes>
            <Route path={RoutePaths.PATIENT_INDEX} Component={PatientIndex} />
            <Route
              path={RoutePaths.PATIENT_CREATE_PAGE}
              Component={PatientCreate}
            />
            <Route path={"/patient/:id"} Component={PatientShow} />
            <Route
              path={RoutePaths.DIAGNOSIS_CODE_INDEX}
              Component={DiagnosisCodeIndex}
            />
            <Route path={RoutePaths.HOME} Component={PatientIndex} />
          </Routes>
        </DashboardContainer>
      </Main>
    </div>
  );
}

export default Dashboard;
