import React from "react";
import { Divider, List, ListItemIcon, ListItemText } from "@mui/material";
import GroupTwoToneIcon from "@mui/icons-material/GroupTwoTone";
import GroupAddTwoToneIcon from "@mui/icons-material/GroupAddTwoTone";
import SortByAlphaTwoToneIcon from "@mui/icons-material/SortByAlphaTwoTone";
import { RoutePaths } from "../../routePaths";
import { useTranslation } from "react-i18next";
import ListItemLink from "../../../shared/widgets/list/ListItemLink";
import { Toolbar } from "../Dashboard";

interface DrawerProps {
  handleDrawerToggle?: () => void;
}

const Drawer: React.FC<DrawerProps> = ({ handleDrawerToggle }: DrawerProps) => {
  const { t } = useTranslation();

  const patientMenuItems = [
    {
      label: t("all.patients"),
      icon: <GroupTwoToneIcon fontSize={"small"} />,
      link: RoutePaths.PATIENT_INDEX,
    },
    {
      label: t("new.patient"),
      icon: <GroupAddTwoToneIcon fontSize={"small"} />,
      link: RoutePaths.PATIENT_CREATE_PAGE,
    },
  ];
  const codesMenuItems = [
    {
      label: t("disease.code.title"),
      icon: <SortByAlphaTwoToneIcon fontSize={"small"} />,
      link: RoutePaths.DIAGNOSIS_CODE_INDEX,
    },
  ];

  return (
    <div>
      <Toolbar />
      <Divider />
      <List>
        {patientMenuItems.map((menuItem, index) => (
          <ListItemLink
            to={menuItem.link}
            onClick={handleDrawerToggle ?? undefined}
            key={index}
          >
            <ListItemIcon>{menuItem.icon}</ListItemIcon>
            <ListItemText primary={menuItem.label} />
          </ListItemLink>
        ))}
      </List>
      <Divider />
      <List>
        {codesMenuItems.map((menuItem, index) => (
          <ListItemLink
            to={menuItem.link}
            onClick={handleDrawerToggle ?? undefined}
            key={index}
          >
            <ListItemIcon>{menuItem.icon}</ListItemIcon>
            <ListItemText primary={menuItem.label} />
          </ListItemLink>
        ))}
      </List>
    </div>
  );
};

export default Drawer;
