import React, { useState } from "react";
import { Button } from "@mui/material";
import GridItem from "../../../../../shared/widgets/container/GridItem";
import GridContainer from "../../../../../shared/widgets/container/GridContainer";
import RemoveDiagnosisEventConfirmationModal from "./RemoveDiagnosisEventConfirmationModal";

import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import SaveTwoToneIcon from "@mui/icons-material/SaveTwoTone";
import PrintTwoToneIcon from "@mui/icons-material/PrintTwoTone";
import AltRouteTwoToneIcon from "@mui/icons-material/AltRouteTwoTone";
import DeleteForeverTwoToneIcon from "@mui/icons-material/DeleteForeverTwoTone";
import OverridablePrintDataModal from "./OverridablePrintDataModal";
import { DiagnosisHistoryDto } from "../../../../../endpoints";

interface DiagnosisEventActionButtonsProp {
  isEditModeEnabled: boolean;
  diagnosisEvent: DiagnosisHistoryDto;
  handleSubmit: () => void;
  handlePrint: () => void;
  handleDelete: () => void;
  handleUpdate: () => void;
  handleOverridablePrintData: (
    overridablePrintData: DiagnosisHistoryDto
  ) => void;
}

const DiagnosisEventActionButtons: React.FC<
  DiagnosisEventActionButtonsProp
> = ({
  isEditModeEnabled,
  diagnosisEvent,
  handlePrint,
  handleSubmit,
  handleDelete,
  handleUpdate,
  handleOverridablePrintData,
}: DiagnosisEventActionButtonsProp) => {
  const [
    openRemoveDiagnosisEventConfirmationModal,
    setOpenRemoveDiagnosisEventConfirmationModal,
  ] = useState<boolean>(false);
  const [openOverridablePrintDataModal, setOpenOverridablePrintDataModal] =
    useState<boolean>(false);

  const handleToggleRemoveDiagnosisEventConfirmationModal = (): void =>
    setOpenRemoveDiagnosisEventConfirmationModal(
      !openRemoveDiagnosisEventConfirmationModal
    );

  const handleToggleOverridablePrintDataModal = (): void =>
    setOpenOverridablePrintDataModal(!openOverridablePrintDataModal);

  return (
    <>
      <RemoveDiagnosisEventConfirmationModal
        open={openRemoveDiagnosisEventConfirmationModal}
        handleAccept={handleDelete}
        handleClose={handleToggleRemoveDiagnosisEventConfirmationModal}
      />
      <OverridablePrintDataModal
        open={openOverridablePrintDataModal}
        diagnosisEvent={diagnosisEvent}
        handleClose={handleToggleOverridablePrintDataModal}
        handleOverridablePrintData={handleOverridablePrintData}
        handlePrint={handlePrint}
      />
      <GridContainer>
        <GridItem xs={4} md={2}>
          <Button
            type="submit"
            onClick={isEditModeEnabled ? handleSubmit : handleUpdate}
            variant="outlined"
            color="primary"
          >
            {isEditModeEnabled ? <SaveTwoToneIcon /> : <EditTwoToneIcon />}
          </Button>
        </GridItem>
        <GridItem xs={4} md={2}>
          <Button
            onClick={() => (handlePrint ? handlePrint() : undefined)}
            variant="outlined"
            color="primary"
          >
            <PrintTwoToneIcon />
          </Button>
        </GridItem>
        <GridItem xs={4} md={2}>
          <Button
            onClick={handleToggleOverridablePrintDataModal}
            variant="outlined"
            color="primary"
          >
            <AltRouteTwoToneIcon />
          </Button>
        </GridItem>
        <GridItem xs={4} md={2}>
          <Button
            onClick={handleToggleRemoveDiagnosisEventConfirmationModal}
            variant="outlined"
            color="primary"
          >
            <DeleteForeverTwoToneIcon />
          </Button>
        </GridItem>
      </GridContainer>
    </>
  );
};

export default DiagnosisEventActionButtons;
